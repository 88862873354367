export interface GlobalConfigs {
  apiUrl: string;
  appName: string;
  schoolName: string;
  schoolEmail: string;
  schoolWebsite: string;
}

type ExtendedWindow = typeof window & { env: GlobalConfigs };

/**
 * Normalizes a URL by adding the appropriate protocol (http:// or https://) if missing.
 * !!! If the URL is provided without protocol to Apollo client link is threat as relative path.
 * @param url - The URL to normalize.
 * @param unsecureMode - Optional. Specifies whether to use the unsecure http:// protocol if missing. Default is false.
 * @returns The normalized URL.
 */
export const normalizeUrl = (url: string, unsecureMode = false): string => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }

  return unsecureMode ? `http://${url}` : `https://${url}`;
};

export const getGlobalConfigs = (): GlobalConfigs => {
  return {
    apiUrl: normalizeUrl(process.env.REACT_APP_API_URL || (window as ExtendedWindow)?.env?.apiUrl || "http://localhost:5000"),
    appName: process.env.REACT_APP_NAME || (window as ExtendedWindow)?.env?.appName || "Omnneo e-learning",
    schoolName: process.env.OMNNEO_SCHOOL_NAME || (window as ExtendedWindow)?.env?.schoolName || "",
    schoolEmail: process.env.OMNNEO_SCHOOL_EMAIL || (window as ExtendedWindow)?.env?.schoolEmail || "",
    schoolWebsite: process.env.OMNNEO_SCHOOL_WEBSITE || (window as ExtendedWindow)?.env?.schoolWebsite || "",
  };
};
